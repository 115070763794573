'use client'
import { type FC, type ReactNode } from 'react'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { ThemeProvider } from 'styled-components'
import { theme } from '@/styles'
import { TranslationsProvider, ModalsProvider, ProviderVideoReady } from '@/context'
import { OverlayProvider } from '@/context/OverlayContext'
import { DivWrapper } from './layout/DivWrapper'
import { Navbar } from './layout/Navbar'
import { Footer } from './layout/Footer'
import { AnimatePresence } from 'framer-motion'
import { PageTransitionProvider } from '@/context/PageTransitionContext'
import { TransitionWrapper } from './TransitionWrapper'
// const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <PageTransitionProvider>
          <OverlayProvider>
            <TranslationsProvider>
              <ProviderVideoReady>
                <ModalsProvider>
                  <DivWrapper />
                  <Navbar />
                  <AnimatePresence initial={false} mode='wait'>
                    <TransitionWrapper>
                      {children}
                    </TransitionWrapper>
                  </AnimatePresence>
                  {/* <DynamicModalMenu /> */}
                  <Footer />
                </ModalsProvider>
              </ProviderVideoReady>
            </TranslationsProvider>
          </OverlayProvider>
        </PageTransitionProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
