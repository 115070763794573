'use client'
import { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { getTransition, mq, vw } from '@/styles'
import { useTranslator } from '@/hooks'

export const ThemePS = (({ isActive, close }) => {
  const { t } = useTranslator()
  const refAudio = useRef(null)
  const controls = useAnimation()

  const handleLoad = useCallback(() => {
    refAudio.current.play()
    controls.start('animate')
  }, [])

  useEffect(() => {
    if (refAudio.current) {
      if (!isActive) {
        refAudio.current.pause()
        refAudio.current.currentTime = 0
      }
    }
  }, [isActive])

  return (
    <Main onClick={close}>
      <motion.figure initial='initial' animate={controls} variants={variantsLogo} className='klk'>
        <img src='/images/contact/dy-ps-logo.svg' alt='Logo' />
      </motion.figure>
      <a target='_blank' rel='noreferrer' href='mailto:hola@you.digital' title={t('navbar.contact') as string}>
        <motion.figure initial='initial' animate={controls} variants={variantsText}>
          <img src='/images/contact/dy-ps-text.svg' alt='mailto'/>
        </motion.figure>
      </a>
      <audio onCanPlayThrough={handleLoad} ref={refAudio}>
        <source src='/sounds/theme-ps.mp3' type='audio/mpeg' />
        <source src='/sounds/theme-ps.ogg' type='audio/ogg' />
      </audio>
    </Main>
  )
})

const Main = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
  flex-direction: column;
  padding-top: ${vw(50, 'mobile')};

  ${mq.greaterThan('tablet')} {
    padding-top: ${vw(80, 'desktop')};
  }

  figure.klk {
    margin: 0 auto ${vw(30, 'mobile')};
    width: ${vw(299, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin: 0 auto ${vw(30, 'desktop')};
      width: ${vw(598, 'desktop')};
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  > a {
    z-index: 2;
    display: block;
    margin: 0 auto;

    figure {
      width: ${vw(273, 'mobile')};
      margin-bottom: ${vw(214, 'mobile')};

      img {
        width: 100%;
        height: 100%;
      }
    }

    figure {
      ${mq.greaterThan('tablet')} {
        width: ${vw(546.22, 'desktop')};
        margin-bottom: ${vw(10, 'desktop')};
      }
    }
  }
`

const variantsLogo = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      delay: 1.3,
      duration: 1.5,
    }
  }
}

const variantsText = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      delay: 2.6,
      duration: 1
    }
  }
}
