'use client'
import styled from 'styled-components'
import { hideScrollbar, mq, vw, getP98 } from '@/styles'
import { useTranslator } from '@/hooks'

const text = '  hola@you.digital   hola@you.digital   hola@you.digital   hola@you.digital   hola@you.digital '

export const ThemeMarquee = (({ close }) => {
  const { t } = useTranslator()

  const sliderMails = () => {
    return [
      <p key='m1'><a href='mailto:hola@you.digital' target='_blank' rel='noreferrer' title={t('navbar.contact') as string}>{text}</a></p>,
      <p key='m2'><a href='mailto:hola@you.digital' target='_blank' rel='noreferrer' title={t('navbar.contact') as string}>{text}</a></p>,
      <p key='m3'><a href='mailto:hola@you.digital' target='_blank' rel='noreferrer' title={t('navbar.contact') as string}>{text}</a></p>
    ]
  }

  return (
    <Main onClick={close}>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={false}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <MarqueeSliderWrapper $textWidth={text.length} className='marquee' $inverted={true}>
        {sliderMails()}
      </MarqueeSliderWrapper>
      <Container>
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 900"><defs></defs><path className="cls-1" d="M720.4,452.11c-31.1,66.94-237.82,182.4-338.9,35.58s44.75-254.81,127.61-263.5"/>
          <image className='follow-1' href="/gifs/typecat_1.gif" height="200" width="200"/>
        </svg>
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 900">
          <path className="cls-1" d="M718.4,453.11Q759.94,537,746.09,608C732.24,679,677.45,824.38,476,833.81s-231-88-231-147.12"/>
          <image className='follow-2' href="/gifs/typecat_2.gif" height="200" width="200"/>
        </svg>
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 900">
          <path className="cls-1" d="M720.4,451.11c0,51.21,48.52,243.17,248.36,221.19s225.66-158.8,205.14-229.44"/>
          <image className='follow-3' href="/gifs/typecat_3.gif" height="200" width="200"/>
        </svg>
      </Container>
    </Main>
  )
})

const Main = styled.main`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: ${vw(50, 'mobile')};
  overflow: hidden;
  background-color: var(--color-white);

  ${mq.greaterThan('tablet')} {
    padding-top: ${vw(77, 'desktop')};
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template: 1fr / 1fr;
  pointer-events: none;
  user-select: none;

  svg {
    justify-self: center;
    scale: 1.2;

    path {
      opacity: 0;
    }

    width: 100%;
    height: 100%;
    grid-area: 1 / 1 / span 1 / span 1;

    .follow-1 {
      motion-path: path("M717.76,428.27c.58,24.68-16.59,47.57-38.85,61.5S629.82,510,603.33,514.7c-126.77,22.28-298.27-60.42-303-173C293.5,179.5,428.86,140.3,481.63,142.14");
      offset-path: path("M717.76,428.27c.58,24.68-16.59,47.57-38.85,61.5S629.82,510,603.33,514.7c-126.77,22.28-298.27-60.42-303-173C293.5,179.5,428.86,140.3,481.63,142.14");
      animation: move-1 2s ease-in forwards;
    }

    .follow-2 {
      motion-path: path("M715.15,437c-28,27.86-38.57,70.21-33.56,109.41s24,75.47,48,106.86c49.7,65,126.8,113.35,208.6,111.29,84.53-2.13,160.39-57.13,210.39-125.33a421.81,421.81,0,0,0,57.72-109A409.15,409.15,0,0,0,1150,414.72c1.68-34.1-2.29-71.56-26.18-96");
      offset-path: path("M715.15,437c-28,27.86-38.57,70.21-33.56,109.41s24,75.47,48,106.86c49.7,65,126.8,113.35,208.6,111.29,84.53-2.13,160.39-57.13,210.39-125.33a421.81,421.81,0,0,0,57.72-109A409.15,409.15,0,0,0,1150,414.72c1.68-34.1-2.29-71.56-26.18-96");
      animation: move-2 2s ease-in forwards;
    }

    .follow-3 {
      motion-path: path("M717.35,436s89.95,188.18-26,290.84c-66.55,58.94-164,72-249.61,66.54-30.69-1.94-61.62-6.43-89.94-18.43C275.08,742.43,243.61,650.74,248.43,572.5");
      offset-path: path("M717.35,436s89.95,188.18-26,290.84c-66.55,58.94-164,72-249.61,66.54-30.69-1.94-61.62-6.43-89.94-18.43C275.08,742.43,243.61,650.74,248.43,572.5");
      animation: move-3 2s ease-in forwards;
    }

    .cls-1{fill:none;stroke:#979797;stroke-miterlimit:10;}
  }

  @keyframes move-1 {
    0% {
      motion-offset: 0%;
      offset-distance: 0%;
      transform: scale(0);
    }
    100% {
      motion-offset: 100%;
      offset-distance: 100%;
      transform: scale(1) rotate(358deg);
    }
  }

  @keyframes move-2 {
    0% {
      motion-offset: 0%;
      offset-distance: 0%;
      transform: scale(0);
    }
    100% {
      motion-offset: 100%;
      offset-distance: 100%;
      transform: scale(1) rotate(495deg);
    }
  }

  @keyframes move-3 {
    0% {
      motion-offset: 0%;
      offset-distance: 0%;
      transform: scale(0);
    }
    100% {
      motion-offset: 100%;
      offset-distance: 100%;
      transform: scale(1) rotate(86deg);
    }
  }
`

const MarqueeSliderWrapper = styled.div<{ $textWidth: number, $inverted: boolean }>`
  ${hideScrollbar()}
  background-color: transparent;
  position: relative;
  display: flex;
  padding: ${vw(10, 'mobile')} 0;

  &:first-child {
    padding: 0 0 ${vw(10, 'mobile')} 0;
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(15, 'desktop')} 0;

    &:first-child {
      padding: 0 0 ${vw(15, 'desktop')} 0;
    }
  }

  p {
    ${getP98()}
    color: var(--color-black);
    white-space: nowrap;
    animation: ${({ $inverted }) => $inverted ? 'marquee-inverted' : 'marquee'} cubic-bezier(0,.44,.91,1) infinite;
    animation-duration: ${({ $textWidth }) => $textWidth / 3}s;

    a {
      white-space: pre;
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  @keyframes marquee-inverted {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
`
