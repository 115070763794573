'use client'
import { FC } from 'react'
import { useParams, usePathname } from 'next/navigation'
import { Link } from '@/components/Link'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'
import { mq, vw, createUnderline, getP15 } from '@/styles'

const whitePages = ['/', '/es', '/en/team-up', '/es/team-up']

const getBgColor = pathname => whitePages.includes(pathname) ? 'text' : 'white'

export const Footer:FC = () => {
  const { lang } = useParams()
  const { t } = useTranslator()
  const pathname = usePathname()

  return (
    <FooterStyled $bgColor={getBgColor(pathname)}>
      <p>{t('footer.text')} <Link href={lang === 'es' ? '/es/aviso' : '/en/notice'}>{t('footer.legal')}</Link></p>
    </FooterStyled>
  )
}

const FooterStyled = styled.footer<{ $bgColor: string }>`
  background-color:  ${({ $bgColor }) => `var(--color-${$bgColor})`};
  margin-top: auto;
  padding: ${vw(30, 'mobile')} ${vw(20, 'mobile')};
  transition: 200ms background-color ease-out;

  ${mq.greaterThan('tablet')} {
    padding: ${vw(30, 'desktop')};
  }

  p {
    color: ${({ $bgColor }) => $bgColor === 'text' ? 'var(--color-white)' : 'var(--color-text)'};
    display: flex;
    opacity: .5;
    transition: 200ms color ease-out;
    ${getP15()}

    a {
      ${({ $bgColor }) => createUnderline({ height: 1, reverse: true, color: $bgColor === 'text' ? 'white' : 'text' })}
      color: inherit;
      display: inline-block;
      margin-left: auto;

      span {
        opacity: .5;
      }
    }
  }
`
