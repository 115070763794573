import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"/fonts/Telegraf/Telegraf-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"/fonts/Telegraf/Telegraf-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"/fonts/Telegraf/Telegraf-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"}],\"variable\":\"--font-telegraf\"}],\"variableName\":\"telegraf\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"/fonts/Graphik/Graphik-Semibold.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"/fonts/Graphik/Graphik-SemiboldItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"/fonts/Graphik/Graphik-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"/fonts/Graphik/Graphik-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"}],\"variable\":\"--font-graphik\"}],\"variableName\":\"graphik\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ClientProviders.tsx");
