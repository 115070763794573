'use client'
import { useEffect, useState } from 'react'
import lottie from 'lottie-web'
import { useOverlay } from '@/hooks'

export interface ILottie {
  name: any,
  speed?: number
}

const useLottie = (name, loop = true) => {
  const [ref, setRef] = useState(null)
  const [animation, setAnimation] = useState(null)

  const register = (ref) => {
    if (ref) {
      setRef(ref)
    }
  }

  useEffect(() => {
    if (ref) {
      const _animation = lottie.loadAnimation({
        container: ref,
        renderer: 'svg',
        loop,
        autoplay: false,
        path: `/lottie/${name}.json`,
        name
      })

      setAnimation(_animation)
    }

    return () => animation && animation.destroy()
  }, [ref])

  return { register, animation }
}

export const Lottie = ({ name, speed = 1 }:ILottie) => {
  const { isActive } = useOverlay()
  const { animation, register } = useLottie(name)

  useEffect(() => {
    if (animation && isActive) {
      animation.setSpeed(speed)
      animation.play()
    }

    return () => animation && animation.stop()
  }, [isActive, animation])

  return (
    <div className={'lottie-container ' + name} ref={register}/>
  )
}

export const ButtonLottie = ({ isModalActive = false, name, speed = 1, onClick = null }) => {
  const { animation, register } = useLottie(name, false)
  const [isActive, setActive] = useState(true)

  useEffect(() => {
    if (animation) {
      animation.setSpeed(speed)
    }

    return () => {
      animation && animation.destroy()
    }
  }, [animation])

  const handleAnimation = () => {
    animation.setDirection(isActive ? 1 : -1)
    animation.play()
    setActive((old) => !old)
  }

  const handleClick = () => {
    handleAnimation()
    onClick && onClick()
  }

  useEffect(() => {
    if (isActive !== !isModalActive) {
      handleAnimation()
    }
  }, [isModalActive])

  return (
    <button onClick={handleClick} className={'lottie-button-' + name} ref={register}/>
  )
}
