'use client'
import { FC, memo, useEffect, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useDeviceType, useModal, useMount, useTranslator } from '@/hooks'
import { MODALS } from '@/context'
import { mq, vw, getTransition } from '@/styles'
import { ThemeMarquee, ThemeGoth, ThemePets } from '.'
// ThemePS, ThemePPG

type Props = {
  soundEffect: HTMLAudioElement
}

const ModalThemes = {
  // 0: (props) => <ThemePS {...props}/>,
  1: (props) => <ThemeMarquee {...props} />,
  2: (props) => <ThemePets {...props} />,
  3: (props) => <ThemeGoth {...props} />,
}

const ALL_THEMES = [1, 2, 3]
// const ALL_THEMES = [0,1,2,3]
let currentSeen = []
let currentIdx = 1
// let currentIdx = 0
let watching = false

export const ModalContact:FC<Props> = memo(({ soundEffect }) => {
  const { isActive, updatePayload } = useModal(MODALS.CONTACT)
  const pathname = usePathname()
  const isMount = useMount()
  const { t } = useTranslator()
  const { isMobile } = useDeviceType()

  const closeModal = () => {
    updatePayload({ close: true })
  }

  const Modal = useMemo(() => {
    if (!isActive) {
      watching = false
      return <></>
    }

    if (watching) return ModalThemes[currentIdx]({ close: closeModal, isActive, soundEffect })

    currentIdx = ALL_THEMES[Math.floor(Math.random() * ALL_THEMES.length)]
    if (currentSeen.length === ALL_THEMES.length) currentSeen = []

    while (currentSeen.includes(currentIdx)) {
      currentIdx = ALL_THEMES[Math.floor(Math.random() * ALL_THEMES.length)]
    }

    currentSeen = [...currentSeen, currentIdx]
    watching = true

    return ModalThemes[currentIdx]({ close: closeModal, isActive, soundEffect })
  }, [closeModal, isActive, soundEffect])

  useEffect(() => {
    return () => { currentSeen = [] }
  }, [])

  useEffect(() => {
    updatePayload({ close: true })
  }, [pathname])

  return (
    isMount &&
    <Aside className={isActive ? 'active' : ''} variants={variants} custom={isMobile} animate={isActive ? 'active' : 'inactive'}>
      {isActive && <>
        <audio src='/sounds/silence.mp3' autoPlay={true}></audio>
        {Modal}
        <ButtonClose type='button' onClick={closeModal} aria-label={t('modal.close') as string}>
          <img src={`/images/svg/i--close-modal${currentIdx !== 1 ? '--white' : ''}.svg`} alt='close modal icon' />
        </ButtonClose>
      </>}
    </Aside>
  )
})


const ButtonClose = styled.button`
  position: fixed;
  right: ${vw(15, 'mobile')};
  top: ${vw(15, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('tablet')} {
    right: ${vw(15, 'desktop')};
    top: ${vw(15, 'desktop')};
  }
`

const Aside = styled(motion.aside)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99999;
  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  opacity: 0;
  min-height: -webkit-fill-available;

  &.active {
    pointer-events: initial;
    user-select: initial;
  }
`

// background-color: ${({ $overlay }) => $overlay ? `rgba(0,0,0, .3)` : `rgba(0,0,0, 0)`};
export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  pointer-events: none;
  z-index: -1;
  transition: opacity 300ms ease;

  &.active {
    opacity: 1;
    pointer-events: initial;
    user-select: initial;
  }
`

const transition = {
  duration: .3,
  type: 'tween',
  ease: [.25, .1, .25, 1],
}

const variants = {
  active: (isMobile) => isMobile ? {
    y: '0px',
    x: '0',
    opacity: 1,
    transition
  } : {
    x: '0',
    y: '0px',
    opacity: 1,
    transition
  },
  inactive: (isMobile) => isMobile ? {
    y: '10px',
    x: '0',
    opacity: 0,
    transition
  } : {
    x: '0',
    y: '10px',
    opacity: 0,
    transition
  }
}

export const imageVariants = {
  initial: {
    opactity: 0,
    transition: {
      ...getTransition(),
      duration: 1,
    }
  },
  animate: {
    opactity: 1,
    transition: {
      ...getTransition(),
      duration: 1,
    }
  },
  exit: {
    opactity: 0,
    transition: {
      ...getTransition(),
      duration: .5,
    }
  }
}
