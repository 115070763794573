'use client'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { getTransition, mq, vw } from '@/styles'

interface IThemeGoth {
  isActive: boolean
  close: any,
  soundEffect: any
}

export const ThemeGoth = memo<IThemeGoth>(({ isActive, close, soundEffect  }) => {
  const refAudio = useRef(null)
  const constraintsRef = useRef(null)
  const [_activeLink, setActive] = useState(true)

  useEffect(() => {
    if (refAudio.current) {
      if (isActive) {
        soundEffect.src = refAudio.current.src
        refAudio.current.play()
      } else {
        refAudio.current.pause()
        refAudio.current.currentTime = 0
      }
    }
  }, [isActive])

  const handleDragStart = useCallback(() => {
    setActive(false)
  }, [])

  const handleDragEnd = useCallback(() => {
    setActive(true)
  }, [])

  const handleDrag = useCallback((e) => {
    e.stopPropagation()
  }, [])

  return (
    <Main ref={constraintsRef}>
      <div className='overlay' onClick={close}></div>
      <motion.div className='drag-area' onDrag={handleDrag} onDragStart={handleDragStart} onDragEnd={handleDragEnd} whileTap={{ cursor: 'grabbing' }} variants={variants} initial='initial' animate='animate' drag dragConstraints={constraintsRef}>
        <motion.a href='mailto:hola@you.digital' rel='noreferrer' target='_blank' variants={imgVariants} initial='initial' animate='animate' >
          <figure>
            <img src='/images/svg/contact--goth.svg' width='1265' height='199' alt='Email contact icon' loading='lazy' />
          </figure>
          <figure className='white'>
            <img src='/images/svg/contact--goth.svg' alt='Email contact icon' />
          </figure>
        </motion.a>
      </motion.div>
      <audio loop autoPlay ref={refAudio}>
        <source src='/sounds/theme-goth.mp3' type='audio/mpeg' />
        <source src='/sounds/theme-goth.ogg' type='audio/ogg' />
      </audio>
    </Main>
  )
})

const Main = styled(motion.main)`
  background: url('/images/contact/blue-flames.gif');
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .drag-area {
    position: absolute;
    top: 50%;
    display: block;
    width: fit-content;
    cursor: grab;
    z-index: 10;
    ${mq.greaterThan('tablet')} {
      top: 35%;
    }
  }

  .overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }

  figure {
    width: ${vw(374, 'mobile')};
    margin: 0 auto ${vw(62, 'mobile')};
    pointer-events: none;
    user-select: none;

    ${mq.greaterThan('tablet')} {
      width: ${vw(1241.26, 'desktop')};
      margin: 0 auto ${vw(75, 'desktop')};
    }

    img {
      pointer-events: none;
      user-select: none;
      width: 100%;
      height: 100%;
      filter: drop-shadow( 0px 36px 34px #0010FF);
    }
  }

  figure.white {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    img {
      filter: drop-shadow( 0px 0px 10px #FFFFFF);
    }
  }
`

const variants = {
  initial: {
    opacity: 0,
    y: '30px',
    transition: {
      ...getTransition()
    }
  },
  animate: {
    opacity: 1,
    y: '0px',
    transition: {
      ...getTransition(),
      delay: .3,
      duration: 3
    }
  }
}

const imgVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      delay: 2,
      duration: 1
    }
  }
}
