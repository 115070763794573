'use client'
import React from 'react'
import styled from 'styled-components'
import { Root, Trigger, Content, Item} from '@radix-ui/react-dropdown-menu'
import { mq, vw, getP20 } from '@/styles'

export const DropdownMenu = Root

export const DropdownMenuTrigger = styled(Trigger)`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: ${vw(7, 'mobile')};

  ${mq.greaterThan('tablet')} {
    column-gap: ${vw(7, 'desktop')};
  }

  img {
    transition: 300ms transform linear;
  }

  input {
    ${getP20()}
  }

  &[aria-expanded='true'] img {
    transform: rotate(180deg);
  }
`

export const StyledContent = styled(Content)`
  box-shadow: 0 2px 4px 0px #a0a0a0;
  background-color: white;
  width: 100%;
`

export const DropdownMenuContent = ({ children }) => {
  return (
    <StyledContent>
      {children}
    </StyledContent>
  )
}

export const DropdownMenuItem = styled(Item)`
  padding: ${vw(12, 'mobile')};
  border-bottom: 1px solid rgba(0,0,0,0.15);
  color: #A6A6A6;
  cursor: pointer;
  outline: none;
  transition: color 300ms linear;

  ${mq.greaterThan('tablet')} {
    padding: ${vw(12, 'desktop')};
  }

  &:hover {
    color: var(--color-black);
  }
`
