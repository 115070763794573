'use client'
import styled from 'styled-components'
import { mq, vw } from '@/styles'
import { Lottie } from '../Lottie'
import { useTranslator } from '@/hooks'

const Main = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  .lottie-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

  }

  > a {
    z-index: 2;
    display: block;
    margin: 0 auto;
    font-size: 81px;
    color: white;

    figure {
      width: ${vw(374, 'mobile')};
      margin-bottom: ${vw(60, 'mobile')};

      img {
        width: 100%;
        height: 100%;
      }
    }

    figure {
      ${mq.greaterThan('tablet')} {
        width: ${vw(655, 'desktop')};
        height: ${vw(310, 'desktop')};
        margin-bottom: ${vw(44, 'desktop')};

        img {
          filter: drop-shadow( 0px 0px 11px #FFF400);
        }
      }
    }
  }
`

export const ThemePPG = (({ close }) => {
  const { t } = useTranslator()

  return (
    <Main onClick={close}>
      <Lottie name='ppg-background' speed={1} />
      <a target='_blank' rel='noreferrer' href='mailto:hola@you.digital' title={t('navbar.contact') as string}>
        HOLA@YOU.DIGITAL
      </a>
    </Main>
  )
})
