'use client'
import { memo, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { vw, mq } from '@/styles'

type TypeBurger = {
  isActive: boolean,
  color?: string,
  onClick: MouseEventHandler<HTMLButtonElement>

}

export const Burger = memo(({ isActive = false, color = '', onClick }:TypeBurger) => {
  return (
    <Button title='Menu' onClick={onClick} $isActive={isActive} color={color} />
  )
})

const Button = styled(motion.button) <{ $isActive: boolean }>`
  height: ${vw(10, 'mobile')};
  width: ${vw(24, 'mobile')};
  position: relative;
  grid-column: 6 / span 1;
  justify-self: end;
  z-index: 2000;

  ${mq.greaterThan('tablet')} {
    display: ${({ $isActive }) => $isActive ? 'initial' : 'none'};
    grid-column: 12 / span 1;
    pointer-events: ${({ $isActive }) => $isActive ? 'auto' : 'none'};
    user-select: ${({ $isActive }) => $isActive ? 'auto' : 'none'};
  }

  &:before,
  &:after {
    content: '';
    background-color: ${({ $isActive, color }) => (color === 'dark' || $isActive) ? 'white' : 'var(--color-text)'};
    height: ${({ $isActive }) => $isActive ? '1px' : '2px'};
    position: absolute;
    left: 0;
    transform: ${({ $isActive }) => $isActive ? 'translateY(-50%)' : 'none'};
    transition: 200ms transform, 200ms top ${({ $isActive }) => $isActive ? '0ms' : '200ms'}, 200ms bottom ${({ $isActive }) => $isActive ? '0ms' : '200ms'}, 150ms height ${({ $isActive }) => $isActive ? '50ms' : '200ms'}, 130ms background-color ${({ $isActive }) => $isActive ? '70ms' : '220ms'};
    transition-timing-function: ease-in-out;
    width: 100%;
  }

  &:before {
    top: ${({ $isActive }) => $isActive ? '50%' : '0'};
  }

  &:after {
    bottom: ${({ $isActive }) => $isActive ? '50%' : '0'};
  }
`
